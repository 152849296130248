@use "../assets/variables" as *;

.introduction-wrapper {
  .introduction-container {
    display: flex;
    flex-direction: column;
    gap: 50px;

    .quote-section {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 30px;

      flex-wrap: wrap;

      .quote-emoji {
        display: block;
        font-size: 82px;
      }

      .quote {
        display: block;
        font-family: 'Permanent Marker', cursive;
        font-weight: bold;
        font-size: 72px;
        font-style: italic;
      }
    }

    .description-section {
      font-size: 22px;
      font-weight: 900;
    }

    .points-section {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .point {
        display: flex;
        align-items: center;
        gap: 20px;

        .icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 30px;
          min-height: 30px;

          border-radius: 100px;

          .icon {
            font-size: 15px;
            color: white;
          }

        }

        .text {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
}
