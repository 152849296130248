@use "../assets/variables" as *;

.creators-wrapper {
  position: relative;

  padding-top: 30px;

  .badge {
    position: absolute;
    top: 0;
    right: 5%;
    width: 55px;
    height: 65px;

    border-radius: 0 0 10px 10px;

    background-color: var(--paper);
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 28px;
      color: var(--passion);
    }
  }

  .stats-section {
    .stats-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      // background-color: var(--paper);
      border: 3px solid #e3d09e;
      padding: 20px;
      border-radius: 20px;

      span,
      p {
        width: fit-content;
        display: block;
        font-weight: 900;
      }

      .number {
        font-size: 52px;
      }

      @media only screen and (max-width: $mobile-break-point) {
        .number {
          font-size: 32px;
        }
      }

      .text {
        color: #e3d09e;
        line-height: 24px;
        font-size: 22px;
      }
    }
  }

  .creators-carousel-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .creator-container {
      position: relative;
      width: 100%;
      background-color: white;
      border-radius: 20px;
      padding: 20px;
      border: 2px solid var(--paper);
      box-sizing: border-box;
      box-shadow: 0 0 20px var(--paper);

      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;

      .index {
        position: absolute;
        top: 20px;
        right: 20px;

        font-size: 14px;
        font-weight: bold;
        color: var(--grayFont);
      }

      .name-section {
        display: flex;
        gap: 80px;

        @media only screen and (max-width: $mobile-break-point) {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          justify-content: center;
          gap: 20px;
        }

        .name-wrapper {
          display: flex;
          align-items: center;
          gap: 20px;

          .icon {
            font-size: 28px;
            color: var(--passion);
          }

          .name-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .occupation {
              font-size: 14px;
              font-weight: bold;
              color: var(--grayFont);
            }

            .name {
              font-size: 22px;
              font-weight: bold;
            }
          }
        }

        .image {
          display: flex;
          width: 100px;
          height: 100px;
          border-radius: 30px;
          object-fit: cover;
          overflow: hidden;

          border: 8px solid var(--paper);
          // box-shadow: 0 0 10px rgba(0 0 0 / 15%);
          transform: rotate(5deg);
        }
      }

      .social-section {
        .social {
          display: flex;
          align-items: center;
          justify-content: center;
          width: fit-content;
          gap: 5px;

          color: var(--ink);

          margin-top: 10px;
          cursor: pointer;

          .icon {
            display: block;
            color: var(--ink);
            font-size: 18px;
            font-weight: bold;
          }

          .handle {
            display: block;
            font-size: 14px;
            font-weight: bold;
          }


        }
      }


      .comment-section {
        .comment {
          color: var(--grayFont);
          line-height: 48px;
          font-size: 24px;
          font-weight: bold;
          margin: 0;
          max-width: 500px;

          padding: 20px 0;

          .underline {
            font-size: 28px;
            color: var(--font);
          }

          @media only screen and (max-width: $mobile-break-point) {
            line-height: 44px;
            font-size: 22px;

            .underline {
              font-size: 24px;
              color: var(--font);
            }
          }
        }
      }
    }
  }

  .thankyou-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;

    .thankyou {
      display: block;
      text-align: center;
      font-size: 62px;
      font-family: 'Permanent Marker', cursive;
    }
  }
}
