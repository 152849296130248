@use "../../assets/variables" as *;

.section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.card {
    margin: 0 20px;
    border-radius: 50px;
  
    box-shadow: 0 0 20px rgba(0 0 0 / 15%);
  }

  overflow: hidden;
  
  .section-container {
    width: 100%;
    max-width: 1800px;
    padding: 80px 20px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    gap: 50px;

    &.tight {
      max-width: 1000px;
    }

    .section-info-section {
      display: flex;
      flex-direction: column;
      gap: 20px;

      // margin-bottom: 50px;

      .section-title {
        line-height: 1;
        font-size: 82px;
        font-weight: 900;
      }

      .section-description {
        line-height: 1.5;
        font-size: 32px;
        font-weight: bold;
      }
    }
    
  }
}

@media only screen and (max-width: $mobile-break-point) {
  .section-wrapper {

    &.card {
      margin: 0 10px;
      border-radius: 30px;
    }

    .section-container {
      padding: 50px 20px;

      .section-info-section {

        .section-title {
          line-height: 1.2;
          font-size: 52px;
        }

        .section-description {
          line-height: 1.5;
          font-size: 22px;
        }
      }
    }
  }
}