@use "../assets/variables" as *;

.contents-wrapper {
  .contents-container {
    display: flex;
    flex-direction: column;
    gap: 50px;

    .block-types-section {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .icons {
        display: flex;
        align-items: center;
        gap: 30px;

        .icon {
          font-size: 32px;
        }

        @media only screen and (max-width: $mobile-break-point) {
          gap: 20px;

          .icon {
            font-size: 24px;
          }
        }
      }
    }

    .contents {
      .content {
        display: flex;
        flex-direction: column;
        gap: 15px;
  
        .screenshot {
          width: 250px;
          border-radius: 20px;
          border: 3px solid white;
          box-shadow: 0 0 20px rgba(255 255 255 / 100%);
  
          transition: transform 0.3s ease;
          &:hover {
            transform: scale(1.03);
          }
        }
  
        .title {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
  
}
