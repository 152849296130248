@use "../assets/variables" as *;

.lp-footer-wrapper {
  margin-top: 100px;
  background-color: white;

  padding: 0 20px;
  padding-top: 30px;
  padding-bottom: 150px;

  .lp-footer-container {
    color: var(--ink);

    .footer-columns {
      display: flex;
      flex-wrap: wrap;
      gap: 80px;

      .footer-column {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 14px;

        .footer-column-title {
          font-size: 16px;
          font-weight: bold;
        }
        
        .column-links {
          display: flex;
          flex-direction: column;
          gap: 10px;

          a {
            color: var(--grayFont);
          }
        }

        .pulp-logo-wrapper {
          display: flex;
          align-items: center;
          gap: 5px;
  
          .logo {
            width: 35px;
            height: 35px;
          }
  
          .text {
            width: 52px
          }

        }

      }
    }

    @media only screen and (max-width: $mobile-break-point) {
      .footer-columns {
        flex-direction: column;
        gap: 35px;
      }
    }

    .copy-right-section {
      width: 100%;
      margin-top: 50px;
      text-align: center;

      span {
        font-size: 14px;
        opacity: .3;
      }
    }
  }
}