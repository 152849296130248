@use "../../assets/variables" as *;

.section-rows-wrapper {
  display: flex;
  flex-direction: column;
  gap: 100px;

  .section-row-wrapper {
    .line {
      margin-top: 100px;
      width: 100%;
    }

    .row-contents {
      display: flex;
      gap: 30px;
      // align-items: flex-start;
      align-items: stretch;

      @media only screen and (min-width: $mobile-break-point) {
        &.swap {
          flex-direction: row-reverse;
          .text-section {
            .body {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }

      .text-section {
        min-width: 30%;

        display: flex;
        flex-direction: column;
        gap: 10px;

        .pre-title {
          font-weight: bold;
        }

        .title {
          font-size: 42px;
          font-weight: bold;
        }

        .description {
          font-size: 22px;
          font-weight: bold;
        }

        .body {
          max-width: 85%;
          margin-left: auto;
          margin-top: auto;
          margin-bottom: 50px;
          font-size: 16px;
        }
      }

      .image-section {
        min-width: 50%;
        border-radius: 20px;
        overflow: hidden;

        .image {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-break-point) {
  .section-rows-wrapper {
    gap: 80px;
    .section-row-wrapper {
      .row-contents {
        flex-direction: column;
        gap: 50px;
        .text-section {
          .title {
            font-size: 32px;
          }

          .description {
            font-size: 18px;
          }

          .body {
            max-width: 100%;
            margin: 0;
          }
        }

        .image-section {
          max-width: 100%;
        }
      }
    }
  }
}
