.signup-wrapper {
  .signup-container {

    display: flex;
    flex-direction: column;
    gap: 50px;

    .join-pulp-image {
      display: block;
      border-radius: 10px;
      width: 100%;
    }

    .signup-button {

      text-decoration: none;

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      color: var(--curiosity);

      border-radius: 10px;
      padding: 20px;

      box-shadow: 0 0 20px rgba(0 0 0 / 15%);

      transition: transform .3s ease;

      cursor: pointer;

      &:hover {
        transform: scale(1.02);
      }

      span {
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
}