@use "../assets/variables" as *;

.lp-header-wrapper {
  width: 100%;
  background-color: white;

  padding: 20px 0;
  box-sizing: border-box;

  border-bottom: 1px solid var(--ink);

  .lp-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left, .right {
      display: flex;
      align-items: center;
      gap: 20px;

      .pulp-logo-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;

        .logo {
          width: 35px;
          height: 35px;
        }

        .text {
          width: 50px
        }

        @media only screen and (max-width: $mobile-break-point) {
          .logo {
            width: 25px;
            height: 25px;
          }
  
          .text {
            width: 40px
          }
        }
      }
    }

    


  }
}
