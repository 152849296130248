@use "../../assets/variables" as *;

.feature-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  .feature-card {
    border-radius: 10px;
    padding: 20px;

    display: flex;
    gap: 20px;

    .emoji {
      font-size: 32px;
    }

    .text-section {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title {
        font-size: 22px;
        font-weight: 900;
      }

      .description {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: $mobile-break-point) {
  .feature-cards-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
