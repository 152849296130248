@use "./assets/variables" as *;
@import url('https://fonts.googleapis.com/css2?family=Kadwa:wght@400;700&family=M+PLUS+1p:wght@400;700;800;900&family=Noto+Sans+JP:wght@400;700;800;900&family=Permanent+Marker&display=swap');

:root {
  // brand colors
  --passion: #ff7f47;
  --curiosity: #4abde1;
  --paper: #f1ede2;
  --ink: #343a4b;

  // element colors
  --font: #292e34;
  --grayFont: #979797;

  // bg colors
  --bgDarkGray: #e2e2e2;
}

body {
  margin: 0;
  // font-family: 'M PLUS 1p', sans-serif;
  font-family: 'Noto Sans JP', sans-serif;

  color: var(--font);

  width: 100vw;
}

img {
  -webkit-touch-callout: none;
  user-select: none;
  // pointer-events: none;
}

@media only screen and (max-width: $mobile-break-point) {
  .mobile-hide {
    display: none;
  }
}

p {
  margin: 0;
  line-height: 1.8;
}

.underline {
  text-underline-offset: 4px;
  text-decoration: dashed underline;
}

.opacity {
  opacity: .8;
}

.opacity-5 {
  opacity: .5;
}