.cta-footer-wrapper {
  position: fixed;
  bottom: 0;
  z-index: 999;

  width: 100%;

  box-sizing: border-box;

  background: white;
  border-radius: 20px 20px 0 0;
  border-bottom: none;

  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  transition: padding .2s ease, background .2s ease, border .2s ease;

  cursor: pointer;

  &.hidden {
    padding-top: 5px;


    background: var(--font);
    border: 3px solid white;
    border-bottom: none;

    .description {
      color: white !important;
      text-align: center;
      font-size: 14px !important;
    }

    .form-section {
      display: none !important;
    }
  }
  
  .section-container {
    padding: 20px;
  }

  .cta-footer-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 500px;

    .handle {
      width: 50px;
      height: 3px;
      border-radius: 100px;
      background-color: white;
      opacity: 50%;
    }

    .description {
      font-weight: bold;
      font-size: 16px;
      color: var(--font);

      transition: font-size .2s ease;
    }

    .form-section {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0;

      height: 35px;

      border-radius: 5px;
      border: 2px solid var(--font);

      overflow: hidden;

      width: 100%;
      max-width: 500px;

      .form-input {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        padding: 10px;
        font-size: 14px;
        border: none;
      }

      .button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        text-decoration: none;
        width: fit-content;
        padding: 10px 20px;
        box-sizing: border-box;
        background-color: var(--bgDarkGray);

        height: 100%;
  
        font-size: 16px;
        font-weight: 900;
        color: white;

        cursor: pointer;

        &.active {
          background-color: var(--font);
        }
  
      }
    }

    
  }
}
