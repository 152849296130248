@use "./assets/variables" as *;

.site-wrapper {
  background-color: var(--paper);
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-height: 100vh;

  .container {
    max-width: 800%;
    padding: 0 20px;
  }

  @media only screen and (max-width: $mobile-break-point) {
    .container {
      padding: 0 10px;
    }
  }

  
}