@use "../assets/variables" as *;

.hero-wrapper {
  min-height: 40vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $mobile-break-point) {
    .hero-container {
      flex-direction: column-reverse;
      gap: 20px;
    }
  }

  .hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;

    flex-wrap: wrap;

    .image-section {
      position: relative;
      width: 30%;

      .main-image {
        width: 100%;
        object-fit: contain;
      }

      .app-icon-wrapper {
        position: absolute;
        top: 0;
        left: 0;

        transform: rotate(-10deg) translate(-30%, -30%);

        width: 30%;
        height: auto;

        border: 5px solid white;
        border-radius: 20px;
        box-shadow: 0 0 30px rgba(0 0 0 / 20%);
        overflow: hidden;

        .app-icon {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      @media only screen and (max-width: $mobile-break-point) {
        .app-icon-wrapper {
          transform: rotate(10deg) translate(10%, 10%);
          top: auto;
          left: auto;
          bottom: 0;
          right: 0;
        }
      }
    }

    @media only screen and (max-width: $mobile-break-point) {
      .image-section {
        width: 90%;
      }
    }

    .messages-section {
      display: flex;
      flex-direction: column;
      gap: 10px;

      max-width: 50%;

      .main-message {
        display: flex;
        flex-direction: column;
        gap: 20px;

        color: var(--ink);
        width: fit-content;

        line-height: 1.5;

        h1,
        p {
          font-weight: 900;
          font-size: 52px;
          margin: 0;
        }

        li {
          margin: 20px 0;
        }

        .big { 
          font-size: 24px;
          color: var(--ink);
        }

        .small {
          font-size: 22px;
        }
      }

      @media only screen and (max-width: $mobile-break-point) {
        .main-message {
          h1 {
            font-size: 42px;
          }

          .small {
            font-size: 18px;
          }
        }
      }

      .icon {
        width: 60%;
        margin: 30px 0;
      }

      @media only screen and (max-width: $mobile-break-point) {
        .icon {
          width: 80%;
        }
      }
    }

    @media only screen and (max-width: $mobile-break-point) {
      .messages-section {
        max-width: 100%;
        gap: 5px;
      }
    }
  }
}
